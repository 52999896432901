import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, Form } from 'react-bootstrap';

function DealDetails() {
  const { id } = useParams();
  const [deal, setDeal] = useState(null);
  const [notes, setNotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [dealStatus, setDealStatus] = useState('Open');

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
        const response = await axios.get(`${apiBaseUrl}/api/epc/deals/${id}`);
        console.log('Deal fetched:', response.data);
        setDeal(response.data);

        // Fetch notes associated with the deal (using the same endpoint as leads)
        const notesResponse = await axios.get(`${apiBaseUrl}/api/epc/leads/${id}/notes`);
        setNotes(notesResponse.data);
      } catch (error) {
        console.error('Error fetching deal details:', error.message);
      }
    };

    fetchDeal();
  }, [id]);

  const handleSaveNote = async () => {
    if (!newNote.trim()) {
      return; // Prevent saving empty notes
    }

    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.post(`${apiBaseUrl}/api/epc/leads/${id}/notes`, { content: newNote });
      
      if (response.status === 201) {
        setNotes([...notes, { content: newNote, createdAt: new Date().toISOString() }]);
        setShowModal(false); // Close the modal after saving
        setNewNote(''); // Clear the note input
      }
    } catch (error) {
      console.error('Error saving note:', error.message);
    }
  };

  const handleDealStatusChange = async (newStatus) => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      // Send PUT request to update the deal status
      const response = await axios.put(`${apiBaseUrl}/api/epc/deals/${id}`, { deal_status: newStatus });
  
      if (response.status === 200) {
        // Update the frontend state only after a successful response
        setDealStatus(newStatus);
        console.log('Deal status updated:', newStatus);
      } else {
        console.error('Failed to update deal status:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating deal status:', error.message);
    }
  };

  if (!deal) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Deal Details Section */}
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h3>Deal Details</h3>
              <p><strong>First Name:</strong> {deal.firstname}</p>
              <p><strong>Last Name:</strong> {deal.surname}</p>
              <p><strong>Deal Status:</strong></p>
              <Form.Control
                as="select"
                value={dealStatus}
                onChange={(e) => handleDealStatusChange(e.target.value)}
              >
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
              </Form.Control>
              <p><strong>Lead Source:</strong> {deal.lead_source}</p>
              <p><strong>Email:</strong> {deal.email}</p>
              <p><strong>Phone:</strong> {deal.phone}</p>
              <p><strong>Address:</strong> {deal.address.line1}, {deal.address.postcode}</p>
              <p><strong>Housing Status:</strong> {deal.housing_status}</p>
              <p><strong>Benefits:</strong> {deal.benefits.join(', ')}</p>
              <p><strong>Current Situation:</strong> {deal.current_situation}</p>
              <p><strong>Gas Boiler:</strong> {deal.gas_boiler ? 'Yes' : 'No'}</p>
              <p><strong>Boiler Installation Year:</strong> {deal.boiler_installation_year}</p>
              <p><strong>Entry Date:</strong> {new Date(deal.entry_date).toLocaleDateString()}</p>
              <p><strong>Created At:</strong> {new Date(deal.createdAt).toLocaleDateString()}</p>
            </div>
          </div>

          <div className="card mb-4">
            <div className="card-body">
              <h3>EPC Data</h3>
              <p><strong>Floor Height:</strong> {deal.floor_height}</p>
              <p><strong>Construction Age Band:</strong> {deal.construction_age_band}</p>
              <p><strong>Potential Energy Rating:</strong> {deal.potential_energy_rating}</p>
              <p><strong>Main Heat Control Description:</strong> {deal.mainheatcont_description}</p>
              <p><strong>Property Type:</strong> {deal.property_type}</p>
              <p><strong>Local Authority Label:</strong> {deal.local_authority_label}</p>
              <p><strong>Mechanical Ventilation:</strong> {deal.mechanical_ventilation}</p>
              <p><strong>County:</strong> {deal.county}</p>
              <p><strong>Solar Water Heating Flag:</strong> {deal.solar_water_heating_flag ? 'Yes' : 'No'}</p>
              <p><strong>Constituency:</strong> {deal.constituency}</p>
              <p><strong>Floor Description:</strong> {deal.floor_description}</p>
              <p><strong>Built Form:</strong> {deal.built_form}</p>
              <p><strong>Windows Description:</strong> {deal.windows_description}</p>
              <p><strong>Total Floor Area:</strong> {deal.total_floor_area}</p>
              <p><strong>Roof Description:</strong> {deal.roof_description}</p>
              <p><strong>Number of Habitable Rooms:</strong> {deal.number_habitable_rooms}</p>
              <p><strong>Main Fuel:</strong> {deal.main_fuel}</p>
              <p><strong>Main Heating Controls:</strong> {deal.main_heating_controls}</p>
              <p><strong>Flat Top Storey:</strong> {deal.flat_top_storey ? 'Yes' : 'No'}</p>
              <p><strong>Current Energy Rating:</strong> {deal.current_energy_rating}</p>
              <p><strong>Second Heat Description:</strong> {deal.secondheat_description}</p>
              <p><strong>Walls Environmental Efficiency:</strong> {deal.walls_env_eff}</p>
              <p><strong>Transaction Type:</strong> {deal.transaction_type}</p>
              <p><strong>Main Heat Description:</strong> {deal.mainheat_description}</p>
              <p><strong>Lodgement Date:</strong> {deal.lodgement_date}</p>
              <p><strong>Tenure:</strong> {deal.tenure}</p>
              <p><strong>Walls Description:</strong> {deal.walls_description}</p>
              <p><strong>Hot Water Description:</strong> {deal.hotwater_description}</p>
            </div>
          </div>
        </div>

        {/* Notes Section */}
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h3>Notes</h3>
              <ul className="timeline">
                {notes.map((note, index) => (
                  <li key={index}>
                    <strong>{new Date(note.createdAt).toLocaleString()}:</strong> {note.content}
                  </li>
                ))}
              </ul>
              <Button variant="primary" onClick={() => setShowModal(true)}>Add Note</Button>
            </div>
          </div>
        </div>

        {/* Modal for adding a note */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="noteContent">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
            <Button variant="primary" onClick={handleSaveNote}>Save Note</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default DealDetails;
