import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, Form } from 'react-bootstrap';

function LeadDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState(null);
  const [notes, setNotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newNote, setNewNote] = useState('');

  useEffect(() => {
    const fetchLead = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
        const response = await axios.get(`${apiBaseUrl}/api/epc/leads/${id}`);
        setLead(response.data);
        // Fetch notes associated with the lead
        const notesResponse = await axios.get(`${apiBaseUrl}/api/epc/leads/${id}/notes`);
        setNotes(notesResponse.data);
      } catch (error) {
        console.error('Error fetching lead details:', error.message);
      }
    };

    fetchLead();
  }, [id]);

  const handleConvertToDeal = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      await axios.post(`${apiBaseUrl}/api/epc/leads/${id}/convert-to-deal`);
      
      // Redirect to the DealDetails page after conversion
      navigate(`/deal/${id}`);
    } catch (error) {
      console.error('Error converting lead to deal:', error.message);
      alert('Failed to convert lead to deal.');
    }
  };

  const handleSaveNote = async () => {
    console.log('Save Note button clicked'); // Add this for debugging
  
    if (!newNote.trim()) {
      console.log('Note is empty');
      return; // Prevent saving empty notes
    }
  
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.post(`${apiBaseUrl}/api/epc/leads/${id}/notes`, { content: newNote });
      
      // Check if the response is successful
      if (response.status === 201) {
        console.log('Note saved successfully');
        setNotes([...notes, { content: newNote, createdAt: new Date().toISOString() }]);
        setShowModal(false); // Close the modal after saving
        setNewNote(''); // Clear the note input
      } else {
        console.error('Failed to save note', response.status);
      }
    } catch (error) {
      console.error('Error saving note:', error.message);
    }
  };

  if (!lead) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h3>Lead Details</h3>
              <p><strong>First Name:</strong> {lead.firstname}</p>
              <p><strong>Last Name:</strong> {lead.surname}</p>
              <p><strong>Lead Status:</strong> {lead.lead_status}</p>
              <p><strong>Lead Source:</strong> {lead.lead_source}</p>
              <p><strong>Email:</strong> {lead.email}</p>
              <p><strong>Phone:</strong> {lead.phone}</p>
              <p><strong>Address:</strong> {lead.address.line1}, {lead.address.postcode}</p>
              <p><strong>Current Situation:</strong> {lead.current_situation}</p>
              <p><strong>Housing Status:</strong> {lead.housing_status}</p>
              <p><strong>Benefits:</strong> {lead.benefits.join(', ')}</p>
              <p><strong>Gas Boiler:</strong> {lead.gas_boiler ? 'Yes' : 'No'}</p>
              <p><strong>Boiler Installation Year:</strong> {lead.boiler_installation_year}</p>
              <p><strong>Entry Date:</strong> {new Date(lead.entry_date).toLocaleDateString()}</p>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h3>EPC Data</h3>
              <table className="table">
                <tbody>
                <tr>
                  <td>Floor Height</td>
                  <td>{lead.floor_height}</td>
                </tr>
                <tr>
                  <td>Construction Age Band</td>
                  <td>{lead.construction_age_band}</td>
                </tr>
                <tr>
                  <td>Potential Energy Rating</td>
                  <td>{lead.potential_energy_rating}</td>
                </tr>
                <tr>
                  <td>Main Heat Controller Description</td>
                  <td>{lead.mainheatcont_description}</td>
                </tr>
                <tr>
                  <td>Property Type</td>
                  <td>{lead.property_type}</td>
                </tr>
                <tr>
                  <td>Local Authority</td>
                  <td>{lead.local_authority_label}</td>
                </tr>
                <tr>
                  <td>Mechanical Ventilation</td>
                  <td>{lead.mechanical_ventilation}</td>
                </tr>
                <tr>
                  <td>County</td>
                  <td>{lead.county}</td>
                </tr>
                <tr>
                  <td>Solar Water Heating</td>
                  <td>{lead.solar_water_heating_flag ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td>Constituency</td>
                  <td>{lead.constituency}</td>
                </tr>
                <tr>
                  <td>Floor Description</td>
                  <td>{lead.floor_description}</td>
                </tr>
                <tr>
                  <td>Built Form</td>
                  <td>{lead.built_form}</td>
                </tr>
                <tr>
                  <td>Windows Description</td>
                  <td>{lead.windows_description}</td>
                </tr>
                <tr>
                  <td>Total Floor Area</td>
                  <td>{lead.total_floor_area}</td>
                </tr>
                <tr>
                  <td>Roof Description</td>
                  <td>{lead.roof_description}</td>
                </tr>
                <tr>
                  <td>Number of Habitable Rooms</td>
                  <td>{lead.number_habitable_rooms}</td>
                </tr>
                <tr>
                  <td>Main Fuel</td>
                  <td>{lead.main_fuel}</td>
                </tr>
                <tr>
                  <td>Main Heating Controls</td>
                  <td>{lead.main_heating_controls}</td>
                </tr>
                <tr>
                  <td>Flat Top Storey</td>
                  <td>{lead.flat_top_storey ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td>Current Energy Rating</td>
                  <td>{lead.current_energy_rating}</td>
                </tr>
                <tr>
                  <td>Secondary Heat Description</td>
                  <td>{lead.secondheat_description}</td>
                </tr>
                <tr>
                  <td>Wall Energy Efficiency</td>
                  <td>{lead.walls_env_eff}</td>
                </tr>
                <tr>
                  <td>Transaction Type</td>
                  <td>{lead.transaction_type}</td>
                </tr>
                <tr>
                  <td>Main Heat Description</td>
                  <td>{lead.mainheat_description}</td>
                </tr>
                <tr>
                  <td>Lodgement Date</td>
                  <td>{lead.lodgement_date}</td>
                </tr>
                <tr>
                  <td>Tenure</td>
                  <td>{lead.tenure}</td>
                </tr>
                <tr>
                  <td>Walls Description</td>
                  <td>{lead.walls_description}</td>
                </tr>
                <tr>
                  <td>Hot Water Description</td>
                  <td>{lead.hotwater_description}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <Button variant="success" onClick={handleConvertToDeal}>
                Convert to Deal
            </Button>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h3>Notes</h3>
              <ul className="timeline">
                {notes.map((note, index) => (
                  <li key={index}>
                    <strong>{new Date(note.createdAt).toLocaleString()}:</strong> {note.content}
                  </li>
                ))}
              </ul>
              <Button variant="primary" onClick={() => setShowModal(true)}>Add Note</Button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for adding a note */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="noteContent">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleSaveNote}>Save Note</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LeadDetails;