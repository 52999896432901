import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Container, Form, Button, Accordion, Row, Col, Pagination } from 'react-bootstrap';

function DealList() {
  const [deals, setDeals] = useState([]);
  const [totalDeals, setTotalDeals] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    main_heating_control: '',
    property_type: '',
    floor_description: '',
    built_form: '',
    windows_description: '',
    total_floor_area: '',
    roof_description: '',
    posttown: '',
    current_energy_rating: '',
    potential_energy_rating: '',
    main_heating_controls: '',
    walls_description: '',
    hotwater_description: '',
    tenure: '',
    main_fuel: '',
    deal_status: '', // New filter field for deal status
    lead_source: ''  // New filter field for lead source
  });

  const [distinctValues, setDistinctValues] = useState({
    property_type: [],
    main_heating_control: [],
    floor_description: [],
    built_form: [],
    windows_description: [],
    total_floor_area: [],
    roof_description: [],
    posttown: [],
    current_energy_rating: [],
    potential_energy_rating: [],
    main_heating_controls: [],
    walls_description: [],
    hotwater_description: [],
    tenure: [],
    main_fuel: [],
    lead_source: [],
    deal_status: ['Open', 'Closed'], // Deal status options
  });

  const limit = 50; // Number of deals per page

  useEffect(() => {
    fetchDeals();
    // Fetch distinct values for dropdowns
    fetchDistinctValues('property_type');
    fetchDistinctValues('main_heating_control');
    fetchDistinctValues('floor_description');
    fetchDistinctValues('built_form');
    fetchDistinctValues('windows_description');
    fetchDistinctValues('total_floor_area');
    fetchDistinctValues('roof_description');
    fetchDistinctValues('posttown');
    fetchDistinctValues('current_energy_rating');
    fetchDistinctValues('potential_energy_rating');
    fetchDistinctValues('main_heating_controls');
    fetchDistinctValues('walls_description');
    fetchDistinctValues('hotwater_description');
    fetchDistinctValues('tenure');
    fetchDistinctValues('main_fuel');
    fetchDistinctValues('lead_source');
    fetchDistinctValues('deal_status');
  }, [currentPage]);

  const fetchDeals = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.get(`${apiBaseUrl}/api/epc/deals`, {
        params: {
          ...filters,
          page: currentPage,
          limit
        }
      });
      console.log('Deals fetched:', response.data);
      setDeals(response.data.deals);
      setTotalDeals(response.data.totalDeals);
    } catch (error) {
      console.error('Error fetching deals:', error.message);
    }
  };

  const fetchDistinctValues = async (field) => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.get(`${apiBaseUrl}/api/epc/distinct/${field}`);
      setDistinctValues(prevState => ({
        ...prevState,
        [field]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching distinct values for ${field}:`, error.message);
    }
  };

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page when applying filters
    fetchDeals();
  };

  const handleReset = () => {
    setFilters({
      main_heating_control: '',
      property_type: '',
      floor_description: '',
      built_form: '',
      windows_description: '',
      total_floor_area: '',
      roof_description: '',
      posttown: '',
      current_energy_rating: '',
      potential_energy_rating: '',
      main_heating_controls: '',
      walls_description: '',
      hotwater_description: '',
      tenure: '',
      main_fuel: '',
      deal_status: '', // Reset deal status
      lead_source: ''  // Reset lead source
    });
    fetchDeals(); // Re-fetch deals with no filters
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <h1 className="my-4">All Deals</h1>

      {/* Filter Accordion */}
      <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter Deals</Accordion.Header>
          <Accordion.Body>
            <Form>
              <Row>
                {/* First Column */}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Main Heating Control</Form.Label>
                    <Form.Select name="main_heating_control" value={filters.main_heating_control} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.main_heating_control.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Property Type</Form.Label>
                    <Form.Select name="property_type" value={filters.property_type} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.property_type.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Floor Description</Form.Label>
                    <Form.Select name="floor_description" value={filters.floor_description} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.floor_description.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Built Form</Form.Label>
                    <Form.Select name="built_form" value={filters.built_form} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.built_form.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Windows Description</Form.Label>
                    <Form.Select name="windows_description" value={filters.windows_description} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.windows_description.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* Second Column */}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Total Floor Area</Form.Label>
                    <Form.Select name="total_floor_area" value={filters.total_floor_area} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.total_floor_area.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Roof Description</Form.Label>
                    <Form.Select name="roof_description" value={filters.roof_description} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.roof_description.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Post Town</Form.Label>
                    <Form.Select name="posttown" value={filters.posttown} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.posttown.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Current Energy Rating</Form.Label>
                    <Form.Select name="current_energy_rating" value={filters.current_energy_rating} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.current_energy_rating.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Potential Energy Rating</Form.Label>
                    <Form.Select name="potential_energy_rating" value={filters.potential_energy_rating} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.potential_energy_rating.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* Third Column */}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Main Heating Controls</Form.Label>
                    <Form.Select name="main_heating_controls" value={filters.main_heating_controls} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.main_heating_controls.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Walls Description</Form.Label>
                    <Form.Select name="walls_description" value={filters.walls_description} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.walls_description.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Hot Water Description</Form.Label>
                    <Form.Select name="hotwater_description" value={filters.hotwater_description} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.hotwater_description.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Tenure</Form.Label>
                    <Form.Select name="tenure" value={filters.tenure} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.tenure.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Main Fuel</Form.Label>
                    <Form.Select name="main_fuel" value={filters.main_fuel} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.main_fuel.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {/* Deal Status Filter */}
                  <Form.Group>
                    <Form.Label>Deal Status</Form.Label>
                    <Form.Select name="deal_status" value={filters.deal_status} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.deal_status.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {/* Lead Source Filter */}
                  <Form.Group>
                    <Form.Label>Lead Source</Form.Label>
                    <Form.Select name="lead_source" value={filters.lead_source} onChange={handleChange}>
                      <option value="">All</option>
                      {distinctValues.lead_source.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Button className="mt-3" onClick={handleSearch}>Search</Button>
              <Button variant="secondary" className="mt-3 ms-2" onClick={handleReset}>Reset</Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Deals Table */}
      <div className="table-responsive mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Deal Status</th>
              <th>Lead Source</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Housing Status</th>
              <th>Benefits</th>
              <th>Current Situation</th>
              <th>Gas Boiler</th>
              <th>Boiler Installation Year</th>
              <th>Entry Date</th>
              <th>Created At</th>
              <th>Floor Height</th>
              <th>Construction Age Band</th>
              <th>Potential Energy Rating</th>
              <th>Address3</th>
              <th>Main Heat Control Description</th>
              <th>Property Type</th>
              <th>Local Authority Label</th>
              <th>Mechanical Ventilation</th>
              <th>County</th>
              <th>Postcode</th>
              <th>Solar Water Heating Flag</th>
              <th>Constituency</th>
              <th>Floor Description</th>
              <th>Local Authority</th>
              <th>Built Form</th>
              <th>Windows Description</th>
              <th>Address1</th>
              <th>Constituency Label</th>
              <th>Total Floor Area</th>
              <th>Roof Description</th>
              <th>Number of Habitable Rooms</th>
              <th>Address2</th>
              <th>Post Town</th>
              <th>Main Fuel</th>
              <th>Main Heating Controls</th>
              <th>Flat Top Storey</th>
              <th>Current Energy Rating</th>
              <th>Second Heat Description</th>
              <th>Walls Environmental Efficiency</th>
              <th>Transaction Type</th>
              <th>Main Heat Description</th>
              <th>Lodgement Date</th>
              <th>Tenure</th>
              <th>Walls Description</th>
              <th>Hot Water Description</th>
            </tr>
          </thead>
          <tbody>
            {deals.map((deal) => (
              <tr key={deal._id}>
                <td>
                  <Link to={`/deal/${deal._id}`}>
                    {deal.firstname}
                  </Link>
                </td>
                <td>{deal.surname}</td>
                <td>{deal.deal_status}</td>
                <td>{deal.lead_source}</td>
                <td>{deal.email}</td>
                <td>{deal.phone}</td>
                <td>{deal.address.line1}, {deal.address.postcode}</td>
                <td>{deal.housing_status}</td>
                <td>{deal.benefits.join(', ')}</td>
                <td>{deal.current_situation}</td>
                <td>{deal.gas_boiler ? 'Yes' : 'No'}</td>
                <td>{deal.boiler_installation_year}</td>
                <td>{new Date(deal.entry_date).toLocaleDateString()}</td>
                <td>{new Date(deal.createdAt).toLocaleDateString()}</td>
                <td>{deal.floor_height}</td>
                <td>{deal.construction_age_band}</td>
                <td>{deal.potential_energy_rating}</td>
                <td>{deal.address3}</td>
                <td>{deal.mainheatcont_description}</td>
                <td>{deal.property_type}</td>
                <td>{deal.local_authority_label}</td>
                <td>{deal.mechanical_ventilation}</td>
                <td>{deal.county}</td>
                <td>{deal.postcode}</td>
                <td>{deal.solar_water_heating_flag ? 'Yes' : 'No'}</td>
                <td>{deal.constituency}</td>
                <td>{deal.floor_description}</td>
                <td>{deal.local_authority}</td>
                <td>{deal.built_form}</td>
                <td>{deal.windows_description}</td>
                <td>{deal.address1}</td>
                <td>{deal.constituency_label}</td>
                <td>{deal.total_floor_area}</td>
                <td>{deal.roof_description}</td>
                <td>{deal.number_habitable_rooms}</td>
                <td>{deal.address2}</td>
                <td>{deal.posttown}</td>
                <td>{deal.main_fuel}</td>
                <td>{deal.main_heating_controls}</td>
                <td>{deal.flat_top_storey ? 'Yes' : 'No'}</td>
                <td>{deal.current_energy_rating}</td>
                <td>{deal.secondheat_description}</td>
                <td>{deal.walls_env_eff}</td>
                <td>{deal.transaction_type}</td>
                <td>{deal.mainheat_description}</td>
                <td>{deal.lodgement_date}</td>
                <td>{deal.tenure}</td>
                <td>{deal.walls_description}</td>
                <td>{deal.hotwater_description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <Pagination className="mt-4">
        {Array.from({ length: Math.ceil(totalDeals / limit) }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
  );
}

export default DealList;
